import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

function Unsub() {
  //obtain referral_code from the route parameter
  const { unsub_code, mode } = useParams();
  // console.log({ referral_code, mode });
  const [unsubResponse, setUnsubResponse] = useState(null);
  const [message, setMessage] = useState("");

  const hasFetched = useRef(false);

  useEffect(() => {
    console.log({
      unsub_code,
      mode,
      unsubResponse,
      hasFetched: hasFetched.current,
    });
    if (hasFetched.current) {
      return;
    }

    hasFetched.current = true;

    // console.log("checking referral code");
    fetch(
      `${process.env.REACT_APP_API_SERVER}/api/pp2/consent/unsubscribe/${mode}/${unsub_code}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({}),
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage("Invitation link is not valid.");
          return;
        }
        setUnsubResponse(data);
        setMessage(data.message);
      })
      .catch(error => {
        console.error(error);
        setUnsubResponse(error);
        setMessage(error.message);
      });
  }, [unsub_code, mode, hasFetched]);

  if (!hasFetched) {
    return (
      <div className="flex justify-center items-center">
        <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center pt-14">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
          <p className="mt-2 text-sm text-gray-600">
            {message}
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Unsub;
