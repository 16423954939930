import React from "react";

function Home() {
  return (
    <div className="container mx-auto">
      <p className="mt-2 text-center text-sm text-gray-600">
        Please use the link we sent you to sign in.
      </p>
    </div>
  );
}

export default Home;
