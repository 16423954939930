import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

const validate_date = (month, day) => {
  //month must resolve to an integer between 1 and 12 inclusive, day must resolve to an integer between 1 and 31 inclusive
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);
  if (isNaN(parsedMonth) || isNaN(parsedDay)) {
    return false;
  }

  if (
    parsedMonth >= 1 &&
    parsedMonth <= 12 &&
    parsedDay >= 1 &&
    parsedDay <= 31
  ) {
    // Valid date
    return true;
  } else {
    // Invalid date
    return false;
  }
};

function SignIn() {
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [message, setMessage] = useState("");
  const [isDoingLandingChecks, setIsDoingLandingChecks] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteLinkValid, setInviteLinkValid] = useState(true);

  //upon loading the page, we have to first do an api call to the backend to check if the referal code is valid and not expired, then we can show the form to the user
  const referralCodeChecked = useRef(false);

  //this is only necessary during Phase 1 - so that subsequent refreshing on this route does not ask for MM DD again
  //so long as the referral code has not expired has and has been validated, and MMDD was already verified
  const [hasAccessToken, setHasAccessToken] = useState(false);

  const [didJustVerifyMMDD, setDidJustVerifyMMDD] = useState(false);

  // const navigate = useNavigate();
  const setAccessToken = jwt => {
    localStorage.setItem(
      process.env.REACT_LC_PREFIX,
      JSON.stringify({
        a: jwt,
        i: new Date().getTime(),
      })
    );
    setHasAccessToken(true);
    // For Project Hermes Phase 1 - we will not ask for user account setup, so just display a thank you and compliance confirmation message
    // Later, we will ask for user account setup, and so we will navigate to the user account setup page
    // navigate("/session/user_account_setup");
  };

  //obtain referral_code from the route parameter
  const { referral_code, mode } = useParams();
  // console.log({ referral_code, mode });

  useEffect(() => {
    if (referralCodeChecked.current) return;
    let rc_local = localStorage.getItem(process.env.REACT_LC_PREFIX + "_rc");
    // let rc_local = false; //for now, we will always check the referral code because we are no
    // if (rc_local) {
    if (false) {
      try {
        rc_local = JSON.parse(rc_local);
        console.log("found referral code in localstorage:", rc_local);
        if (rc_local.rc !== referral_code) {
          console.log(
            "referral code in localstorage does not match the one in the url, unset it from localstorage"
          );
          localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
        } else {
          let three_minutes_from_now = new Date();
          three_minutes_from_now.setSeconds(
            three_minutes_from_now.getSeconds() + 180
          );
          if (
            rc_local.rc === referral_code &&
            rc_local.rc_expires > three_minutes_from_now.getTime()
          ) {
            referralCodeChecked.current = true;
            setIsDoingLandingChecks(false);
            if (localStorage.getItem(process.env.REACT_LC_PREFIX)) {
              setHasAccessToken(true);
            }
            return;
          }
        }
      } catch (e) {
        console.log("corrupt referral code found, unset it from localstorage");
        localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
      }
    }
    checkReferralCode();
  }, []);

  const checkReferralCode = () => {
    // console.log("checking referral code");
    fetch(
      `${process.env.REACT_APP_API_SERVER}/api/pp2/consent/validate_referral_code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({ referral_code, mode }),
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage("Invitation link is not valid.");
          setInviteLinkValid(false);
          setIsDoingLandingChecks(false);
          return;
        }

        //date when the referral code will expire
        let rc_expires = new Date();
        rc_expires.setSeconds(
          rc_expires.getSeconds() + data.seconds_to_rc_expire
        );
        console.log(
          "RC will expire at datetime:",
          new Date(rc_expires).toString()
        );

        //removing this during Phase 1 - we want to check validation of referral code on landing
        localStorage.setItem(
          process.env.REACT_LC_PREFIX + "_rc",
          JSON.stringify({
            rc: referral_code,
            rc_expires: rc_expires.getTime(),
          })
        );

        setIsDoingLandingChecks(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsDoingLandingChecks(false);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("...");
    if (!validate_date(month, day)) {
      // set message to "Invalid date"
      setMessage("Invalid date");
      setIsSubmitting(false);
      return;
    }

    //convert month and day to mmdd format
    const mmdd = month.padStart(2, "0") + day.padStart(2, "0");

    /* 
      Make a cross domain fetch post to the bakcend (server is stored in env var REACT_APP_API_SERVER) at path '/api/pp2/login'
      with body { mmdd, referral_code }. 
      If error, set message to error message from server, if success, call setAccessToken() passing in access token from server
    */
    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/consent/validate_mmdd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ mmdd, referral_code, mode }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage(data.error);
        } else {
          // setAccessToken(data.access_token);
          setDidJustVerifyMMDD(true);
          // remove local storage referral code so that refresh doesn't find the valid referral code
          localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
          // setMessage("...signing you in...");
        }
        setIsSubmitting(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsSubmitting(false);
      });
  };

  //for demo and qa only
  // const handleQAReset = () => {
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX);
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
  //   setMonth("");
  //   setDay("");
  //   setMessage("");
  //   setIsSubmitting(false);
  //   setHasAccessToken(false);
  //   referralCodeChecked.current = false;
  // };

  //end demo and qa stuff

  if (isDoingLandingChecks) {
    return (
      <div className="flex justify-center items-center">
        <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
          <LoadingAnimation />
        </div>
      </div>
    );
  }

  if (!referralCodeChecked) {
    // checkReferralCode();
    return (
      <div className="flex justify-center items-center ">
        <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
          <p className="mt-2 text-center text-sm text-gray-600">
            ...checking referral code...
          </p>
        </div>
      </div>
    );
  }

  if (!inviteLinkValid) {
    return (
      <div className="flex justify-center">
        <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
          <p className="mt-2 text-center text-sm text-gray-600">
            Invitation link is invalid or has expired.
          </p>
        </div>
      </div>
    );
  }

  if (didJustVerifyMMDD) {
    return (
      <div className="flex justify-center pt-14">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
            <p className="mt-2 text-sm text-gray-600">
              Thank you for verifying. You are now subscribed to SuperCare{" "}
              {mode.toUpperCase()} delivery notifications.
              {/* Please call SuperCare or reach us via chat at www.supercarehealth.com to unsubscribe. */}
              {/* <br /> */}
              {/* [Onboarding Milestone 20 reached - User verified MMDD] */}
            </p>
          </div>

          {/* -- for qa and demo only -- */}
          {/* <div className="bg-yellow-100 p-8 rounded-lg shadow-md mt-20 w-80">
            <p className="mt-2 mb-12 text-center text-sm text-gray-600">
              This section is displayed ONLY for DEMO/QA PURPOSES so you can
              retart the flow from the beginning.
            </p>

            <button
              className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
              onClick={handleQAReset}
            >
              Clear Session
            </button>
          </div> */}
        </div>
      </div>
    );
  }

  return (
    <div className="flex justify-center">
      <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
        {/* <p className="mt-2 text-center text-sm text-gray-600">
          Your referral code is: {referral_code}
        </p> */}
        <h2 className="text-1xl font-bold mb-4">
          Please enter the 2 digit month and 2 digit day of the patient's
          birthday:
        </h2>
        <div className="flex mb-4">
          <div className="w-1/2">
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-600 mb-1"
            >
              Month (MM)
            </label>
            <input
              autoComplete="off"
              type="text"
              id="month"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder=""
              value={month}
              onChange={e => setMonth(e.target.value)}
            />
          </div>
          <div className="mx-2 self-center">/</div>
          <div className="w-1/2">
            <label
              htmlFor="day"
              className="block text-sm font-medium text-gray-600 mb-1"
            >
              Day (DD)
            </label>
            <input
              autoComplete="off"
              type="text"
              id="day"
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              placeholder=""
              value={day}
              onChange={e => setDay(e.target.value)}
            />
          </div>
        </div>
        {isSubmitting ? (
          <button className="w-full bg-gray-200 text-white py-2 rounded-md hover:bg-gray-100 transition duration-300">
            ...attempting verification...
          </button>
        ) : (
          <button
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
            onClick={handleSubmit}
          >
            Submit
          </button>
        )}

        {message && <p className="mt-4 text-center">{message}</p>}
        <p className="font-thin text-xs mt-4 pt-8">
          Disclaimer: By verifying the month and day of birth you are consenting
          for SuperCareHealth communicating with you via SMS/email.
        </p>
      </div>

      {/* -- for qa and demo only -- */}
      {/* <div className="bg-yellow-100 p-8 rounded-lg shadow-md mt-20 w-80">
        <p className="mt-2 mb-12 text-center text-sm text-gray-600">
          This section is displayed ONLY for DEMO/QA PURPOSES so you can retart
          the flow from the beginning.
        </p>

        <button
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
          onClick={handleQAReset}
        >
          Clear Session
        </button>
      </div> */}
    </div>
  );
}

export default SignIn;
